import * as React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import { setAppElement } from 'spoton-lib';

import { configureApi } from './utils/axios';
import { ROOT_ELEMENT_ID } from 'utils/constants';
import { App } from 'app';
import './global.css';

interface IDashboardProps {
  /**
   * Is true when running in a Dashboard FE ephemeral
   */
  isEphemeralEnv?: boolean;
}

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  renderType: 'render',
  loadRootComponent: () => Promise.resolve(() => <App />),
  errorBoundary() {
    return <div>Oops! Something went wrong.</div>;
  },
});

// Single SPA lifecycle methods
export const bootstrap = (props: IDashboardProps) => {
  configureApi(Boolean(props.isEphemeralEnv));

  const rootElement = document.getElementById(ROOT_ELEMENT_ID);
  if (rootElement) {
    setAppElement(rootElement);
  }

  return reactLifecycles.bootstrap(props);
};

export const mount = reactLifecycles.mount;
export const unmount = reactLifecycles.unmount;
